.floating-icons-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.floating-icon {
  position: absolute;
  bottom: -2rem;
  white-space: nowrap;
  animation-name: float;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes float {
  0% {
    transform: translateY(0);
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(-110vh);
    opacity: 0;
  }
}
