@import url("https://fonts.googleapis.com/css2?family=Anton&family=Poppins:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&family=Fredoka:wght@300&family=Poppins:wght@300;400;500;600&display=swap");
@media screen and (min-width: 1025px) {
  .projects-featured[data-parallax][style]
    .projects-featured__item:nth-last-child(1)
    .projects-featured__item-info {
    transform: translateX(calc(10vw * var(--parallax-percent)));
  }
  .projects-featured[data-parallax][style]
    .projects-featured__item:nth-last-child(2)
    .projects-featured__item-info {
    transform: translateX(calc(20vw * var(--parallax-percent)));
  }
  .projects-featured[data-parallax][style]
    .projects-featured__item:nth-last-child(3)
    .projects-featured__item-info {
    transform: translateX(calc(30vw * var(--parallax-percent)));
  }
  .projects-featured[data-parallax][style]
    .projects-featured__item:nth-last-child(4)
    .projects-featured__item-info {
    transform: translateX(calc(40vw * var(--parallax-percent)));
  }
  .projects-featured[data-parallax][style]
    .projects-featured__item:nth-last-child(5)
    .projects-featured__item-info {
    transform: translateX(calc(50vw * var(--parallax-percent)));
  }
  .projects-featured[data-parallax][style]
    .projects-featured__item:nth-last-child(6)
    .projects-featured__item-info {
    transform: translateX(calc(60vw * var(--parallax-percent)));
  }
  .projects-featured[data-parallax][style]
    .projects-featured__item:nth-last-child(7)
    .projects-featured__item-info {
    transform: translateX(calc(70vw * var(--parallax-percent)));
  }
  .projects-featured[data-parallax][style]
    .projects-featured__item:nth-last-child(8)
    .projects-featured__item-info {
    transform: translateX(calc(80vw * var(--parallax-percent)));
  }
  .projects-featured[data-parallax][style]
    .projects-featured__item:nth-last-child(9)
    .projects-featured__item-info {
    transform: translateX(calc(90vw * var(--parallax-percent)));
  }
  .projects-featured[data-parallax][style]
    .projects-featured__item:nth-last-child(10)
    .projects-featured__item-info {
    transform: translateX(calc(100vw * var(--parallax-percent)));
  }
  .site-banner--image-cluster[data-parallax][style] .site-banner__img img {
    opacity: 1;
    transition: opacity ease 0.6s;
  }
  .site-banner--image-cluster[data-parallax][style]
    .site-banner__img:nth-child(1)
    .site-banner__img-inner {
    transform: translate(
      calc(20vh * var(--parallax-percent)),
      calc(50vh * var(--parallax-percent))
    );
  }
  .site-banner--image-cluster[data-parallax][style]
    .site-banner__img:nth-child(2)
    .site-banner__img-inner {
    transform: translate(
      calc(-5vh * var(--parallax-percent)),
      calc(15vh * var(--parallax-percent))
    );
  }
  .site-banner--image-cluster[data-parallax][style]
    .site-banner__img:nth-child(3)
    .site-banner__img-inner {
    transform: translate(calc(-50vh * var(--parallax-percent)), 0);
  }
  .site-banner--image-cluster[data-parallax][style]
    .site-banner__img:nth-child(4)
    .site-banner__img-inner {
    transform: translate(
      calc(50vh * var(--parallax-percent)),
      calc(-5vh * var(--parallax-percent))
    );
  }
  .site-banner--image-cluster[data-parallax][style]
    .site-banner__img:nth-child(5)
    .site-banner__img-inner {
    transform: translate(
      calc(-20vh * var(--parallax-percent)),
      calc(-30vh * var(--parallax-percent))
    );
  }
  .site-banner--image-cluster[data-parallax][style]
    .site-banner__img:nth-child(6)
    .site-banner__img-inner {
    transform: translate(
      calc(20vh * var(--parallax-percent)),
      calc(-40vh * var(--parallax-percent))
    );
  }
  .site-banner--image-cluster[data-parallax]:not([style])
    .site-banner__img
    img {
    opacity: 0;
  }
}

.projects-featured {
  position: relative;
  margin-bottom: -100vh;
}

.projects-featured::after {
  content: "";
  height: 100vh;
  display: block;
}

.projects-featured__item,
.projects-featured__item-info::before {
  background-color: var(--color);
}

.projects-featured__item-inner {
  display: flex;
}
.projects-featured__item-info {
  justify-content: center;
}
.projects-featured__item-info.txt-styles h3 a:nth-child(n) {
  text-decoration: none;
}
#section-1 .titleWrapper {
  width: 90%;
  margin: 20px auto;
  text-align: center;
  margin-top: 0px;
}
#section-1 .title {
  display: block;
  color: #121212;
  font-family: "Anton", sans-serif;
  font-weight: 400;
  font-size: 40px;
  text-transform: uppercase;
  line-height: 100%;
}
#section-1 .description {
  margin-top: 20px;
  font-size: 16px;
  font-family: "FK General sans", Arial, sans-serif;
}
#section-1 .user {
  width: 22rem;
  height: 10rem;
  text-align: center;
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 100px;
  box-shadow: 0 10px 20px -5px #00000061;
  border-top-color: #ffffff50;
  border-top-width: 1px;
  opacity: 0.8;
  position: relative;
  margin: 20% auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
}
#section-1 .user img {
  width: 80px;
  border-radius: 50%;
  border: 5px solid #e8ecff;
  position: absolute;
  top: -55px;
  left: 50%;
  transform: translate(-50%);
}

#section-1 .user p {
  font-size: 16px;
  color: #0b0a0b;
  line-height: 25px;
  margin: 25px 0 10px;
  font-family: "FK General sans", Arial, sans-serif;
}
#section-1 .user h3 {
  font-size: 18px;
  color: #0b0a0b;
  font-family: "FK General sans", Arial, sans-serif;
  margin-right: 10px;
}
#section-1 .nameIcon {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media screen and (max-width: 1080px) {
  #section-1 .user {
    width: 300px;
    height: 180px;
  }
}

@media screen and (max-width: 960px) {
  #section-1 .titleWrapper {
    margin: 10px auto;
    text-align: center;
    margin-top: 0px;
  }
  #section-1 .title {
    font-size: 36px;
  }
  #section-1 .description {
    font-size: 16px;
  }
  #section-1 .user {
    width: 90%;
    height: 150px;
    display: flex;
  }
  #section-1 .user img {
    width: 50px;
    top: -35px;
  }
  #section-1 .user p {
    font-size: 14px;
    width: 90%;
    margin: 10px 10px 6px;
    text-align: center;
  }
  #section-1 .user h3 {
    font-size: 14px;
    text-align: center;
    margin-right: 5px;
  }
}

@media screen and (min-width: 800px) {
  .projects-featured__item {
    position: sticky;
    top: 0;
    overflow: hidden;
  }
  .projects-featured__item-inner {
    height: 100vh;
  }
  .projects-featured__item-info {
    width: 34.5263157895%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
  }
  .projects-featured__item-info::before {
    content: "";
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }
  .projects-featured__item-img {
    width: 65.4736842105%;
  }
  .projects-featured__item-img a {
    height: 100%;
    display: flex;
    position: relative;
  }
  .projects-featured__item-img img {
    max-width: none;
    width: calc(100% + (100vw - 152.733118971%) / 2);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .projects-featured__item-excerpt {
    max-width: 420px;
  }
}
section {
  display: block;
  position: relative;
}
.projects-featured {
  position: relative;
  margin-bottom: -100vh;
}
@media screen and (max-width: 800px) {
  .projects-featured__item-info {
    width: 34.5263157895%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
  }
  .projects-featured__item-img {
    width: 65.4736842105%;
  }
  .projects-featured__item-inner {
    width: 100%;
    max-width: none;
  }

  #section-1 .title {
    margin: 20px;
    font-weight: 400;
    font-size: 25px;
  }

  #section-1 .description {
    margin-top: 10px;
    font-size: 13px;
  }

  #section-1 .user {
    width: 90%;
    height: 40%;
  }

  #section-1 .user img {
    width: 50px;
    top: -35px;
  }

  #section-1 .user p {
    font-size: 14px;
    line-height: 15px;
    margin: 6px 0;
  }

  #section-1 .user h3 {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .projects-featured__item-img {
    width: 100%;
    /* height: 70vh; */
  }
  .projects-featured__item-inner {
    max-width: none;
    flex-direction: column-reverse;
  }
  .projects-featured__item-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #section-1 .titleWrapper {
    margin: auto;
    text-align: center;
  }
  #section-1 .title {
    font-weight: 400;
    font-size: 30px;
  }
  #section-1 .description {
    font-size: 15px;
  }
  #section-1 .user {
    width: 28rem;
    height: 150px;
    margin-top: 6%;
    padding: 15px 16px;
    margin-bottom: 2%;
  }
  #section-1 .user img {
    width: 50px;
    top: -30px;
  }
  #section-1 .user p {
    font-size: 15px;
    line-height: 25px;
  }
  #section-1 .user h3 {
    font-size: 15px;
    font-weight: 500;
  }
}
@media screen and (max-width: 568px) {
  .projects-featured__item-inner {
    width: 100%;
    max-width: none;
    flex-direction: column-reverse;
  }
  .projects-featured__item-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #section-1 .titleWrapper {
    width: 100%;
    margin: auto;
    text-align: center;
  }

  #section-1 .title {
    font-weight: 400;
    font-size: 30px;
  }
  #section-1 .description {
    margin-top: 0px;
    font-size: 0px;
  }
  #section-1 .user {
    width: 70%;
    height: 150px;
    margin-top: 11%;
    margin-bottom: 5%;
    padding: 2px 14px;
  }
  #section-1 .user img {
    width: 50px;
    top: -30px;
  }

  #section-1 .user p {
    font-size: 14px;
    line-height: 15px;
    margin: 2z0px 0 10px;
    font-weight: 300;
  }
  #section-1 .user h3 {
    font-size: 14px;
    font-weight: 400;
  }
}
