@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;400&display=swap");
@import url("https://assets.uiowa.edu/css/gotham.css");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Fredoka", Sans-serif;
}
/* #toggleBtn {
  position: fixed;
  top: 38px;
  right: 18px;
  z-index: 1000;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: #000;
} */

#navbar {
  width: 100%;
  height: 100px;
  padding: 5px;
  /* padding-inline: 5%; */
  /* background-color: #000; */
  position: absolute;
  top: 0;
  z-index: 1000;
}

#navbar .subDiv {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

#navbar .logo {
  height: 60px;
  width: 150px;
}
#navbar .ul-Div {
  /* margin-left: 20vh; */
}

#navbar .ul-Div ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#navbar .ul-Div ul li {
  list-style: none;
}

#navbar .ul-Div ul li > a {
  text-decoration: none;
  padding: 0 5px;
  margin: 0px 50px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1.3px;
  font-family: "Gotham A", "Gotham B", Arial, Helvetica, sans-serif;
}

#navbar .ul-Div ul button {
  padding: 10px 20px;
  background-color: white;
  color: #fff;
  margin-left: 10px;
}

#navbar .list {
  cursor: pointer;
}

#navbar .list a {
  position: relative;
  height: 30px;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

#navbar .list a:hover {
  /* box-shadow: 0 0 10px 5px rgba(234, 234, 234, 0.4); */
  /* color: #fc71ae; */
}

.openMenu {
  display: none;
  font-size: 2.2rem;
  /* color: #fc71ae; */
  cursor: pointer;
}

@media screen and (max-width: 960px) {
  #navbar {
    width: 100%;
    height: 100px;
    padding: 5px;
    /* padding-inline: 5%; */
    background-color: #000;
    position: absolute;
    top: 0;
    z-index: 1000;
  }

  #navbar .subDiv {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  #navbar .logo {
    height: 60px;
    width: 150px;
  }
  #navbar .ul-Div {
    /* margin-left: 20vh; */
  }

  #navbar .ul-Div ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #navbar .ul-Div ul li {
    list-style: none;
  }

  #navbar .ul-Div ul li > a {
    text-decoration: none;
    padding: 0 5px;
    margin: 0px 50px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px;
    font-family: "Gotham A", "Gotham B", Arial, Helvetica, sans-serif;
  }

  #navbar .ul-Div ul button {
    padding: 10px 20px;
    background-color: white;
    color: #fff;
    margin-left: 10px;
  }

  #navbar .list {
    cursor: pointer;
  }

  #navbar .list a {
    position: relative;
    height: 30px;
    width: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }

  #navbar .list a:hover {
    /* box-shadow: 0 0 10px 5px rgba(234, 234, 234, 0.4); */
    /* color: #fc71ae; */
  }

  #dropDown_main {
    position: unset;
    /* background-color: blue; */
  }
}
@media screen and (max-width: 768px) {
  #navbar .logo {
    height: 60px;
    width: 150px;
  }
  #navbar .ul-Div {
    margin-left: 20vh;
  }

  .listWrapper {
    background-color: #000;
    border: 1px solid #000;
    border-top-width: 0px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .openMenu {
    display: inline-block;
    height: 40px;
    color: #fff;
    width: 40px;
  }

  .ul-Div ul {
    row-gap: 10px;
    flex-direction: column;
    z-index: 99999999;
    position: absolute;
    top: 110px;
    width: 100%;
  }

  #navbar .list a {
    color: #fff;
  }

  #navbar .list a:hover {
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
    /* color: #fc71ae; */
  }

  #dropDown_main {
    position: unset;
    /* background-color: blue; */
  }
}
