#details {
  background-color: #000;
  background-size: cover;
  padding-top: 100px;
  display: grid;
  width: 100%;
  height: 100vh;
}

#details .subDiv {
  padding-left: 5vw;
  text-decoration: none;
}

#details .title {
  color: white;
  font-size: 4vw;
  font-weight: bold;
  letter-spacing: 1px;
  position: relative;
  font-family: "Gotham A", "Gotham B", Arial, Helvetica, sans-serif;
}

#details .titleName {
  color: #f0445c;
  font-size: 4vw;
  font-weight: bold;
  letter-spacing: 2px;
  position: relative;
  font-family: "Gotham A", "Gotham B", Arial, Helvetica, sans-serif;
}
#details .subTitleContainer {
  margin-top: 6px;
  height: 10vh;
  width: 70%;
  font-family: "Gotham A", "Gotham B", Arial, Helvetica, sans-serif;
}

#details .subTitle {
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  margin-left: 1vw;
  position: relative;
}

#details .video {
  position: absolute;
  opacity: 0.7;
  right: 0;
  bottom: 0;
  height: 80vh;
  width: 85vh;
}
#details .awardsContainer {
  margin-top: 10vh;
  position: relative;
}

@media screen and (max-width: 960px) {
  #details {
    background-color: #000;
    padding-top: 120px;
  }

  #details .video {
    opacity: 0.7;
    right: 0;
    bottom: 0;
    height: 83vh;
    width: 100vh;
  }
  #details .subDiv {
    padding-left: 0vw;
    text-align: center;
    align-items: center;
  }
  #details .subTitleContainer {
    width: 100%;
  }

  #details .subTitle {
    font-size: 1rem;
  }
  #details .awardsContainer {
    margin-top: 10vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
  }
}
